
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Models
import {AppPartnerIndex} from "@/models/app/partner";
import {AppCompensationIndex} from "@/models/app/compensation";
import {AppPaymentTypeIndex} from "@/models/app/payment-type";
import {ResponseIndex} from "@/models/response";
import {PaginationIndex} from "@/models/pagination";

// Components
import {
  ElTable,
  ElTableColumn,
  ElButton,
  ElPagination,
} from "element-plus";
import AppAdministratorInvoicesCompensationsListFormCreateIncomingInvoiceCompensationPaymentIndexVue from "@/views/app/administrator/invoices/compensations/list/form/create-incoming-invoice-compensation-payment.vue";
// import AppAdministratorInvoicesCompensationsListFormCreateOutgoingInvoiceCompensationPaymentIndexVue from "@/views/app/administrator/invoices/compensations/list/form/create-outgoing-invoice-compensation-payment.vue";
import AppAdministratorInvoicesCompensationsListFormFilterIndexVue from "@/views/app/administrator/invoices/compensations/list/form/filter.vue";
import AppAdministratorInvoicesCompensationsListFormLookupIndexVue from "@/views/app/administrator/invoices/compensations/list/form/lookup.vue";
import AppAdministratorInvoicesCompensationsListFormUpdateIndexVue from "@/views/app/administrator/invoices/compensations/list/form/update.vue";
import AppAdministratorInvoicesCompensationsListFormRemoveIndexVue from "@/views/app/administrator/invoices/compensations/list/form/remove.vue";
import LoadingIndexVue from "@/components/loading.vue";

// Services
import {getRequest} from "@/services/api/request";
import {getDateFormat} from "@/services/app/date";
import {getInvoiceNumberInYear, getInformation, getCurrencyFormatEUR} from "@/services/app/data";
import {getTranslation} from "@/services/app/translation";

@Options({
  components: {
    ElTable,
    ElTableColumn,
    ElButton,
    ElPagination,
    AppAdministratorInvoicesCompensationsListFormCreateIncomingInvoiceCompensationPaymentIndexVue,
    // AppAdministratorInvoicesCompensationsListFormCreateOutgoingInvoiceCompensationPaymentIndexVue,
    AppAdministratorInvoicesCompensationsListFormFilterIndexVue,
    AppAdministratorInvoicesCompensationsListFormLookupIndexVue,
    AppAdministratorInvoicesCompensationsListFormUpdateIndexVue,
    AppAdministratorInvoicesCompensationsListFormRemoveIndexVue,
    LoadingIndexVue,
  },
})
export default class AppAdministratorInvoicesCompensationsListIndexVue extends Vue {
  isLoading = false;

  partner_id = "*";
  pagination: PaginationIndex = new PaginationIndex();

  compensation: AppCompensationIndex = new AppCompensationIndex();
  compensations: Array<AppCompensationIndex> = [];
  partners: Array<AppPartnerIndex> = [];
  paymentTypes: Array<AppPaymentTypeIndex> = [];

  $refs!: {
    AppAdministratorInvoicesCompensationsListFormLookupIndexVue: any;
    AppAdministratorInvoicesCompensationsListFormUpdateIndexVue: any;
    AppAdministratorInvoicesCompensationsListFormRemoveIndexVue: any;
  };

  get isDataLoaded(): boolean {
    return this.compensations.length > 0 && this.isLoading === false;
  }

  get isDataLoadedAndEmpty(): boolean {
    return this.compensations.length === 0 && this.isLoading === false;
  }

  get isPaginationVisible(): boolean {
    return this.pagination.last_page > 1 && this.isLoading === false;
  }

  get translation(): any {
    return getTranslation([
      "amount",
      "compensations",
      "date",
      "excerpt",
      "invoiceNumber",
      "invoiceNumberInYearURA",
      "invoiceNumberInYearIRA",
      "ordinalNumber",
      "noData",
      "transaction",
      "partner",
      "paymentPurpose",
      "paymentType",
      "remove",
      "review",
      "edit",
    ]);
  }

  getIsTransactionSet(data: AppCompensationIndex): boolean {
    return data.invoice_payment_incoming.transaction_id !== null;
  }

  getIsOrdinalNumberSet(data: AppCompensationIndex): boolean {
    return data.ordinal_number !== null;
  }

  getDateFormat(data: string): string {
    return getDateFormat(data);
  }

  getCurrencyFormatEUR(data: string): string {
    return getCurrencyFormatEUR(data);
  }

  getInvoiceNumberInYear(number: string, year: string): string {
    return getInvoiceNumberInYear(number, year);
  }

  getInformation(data: string): string {
    return getInformation(data);
  }

  async getCompensations(partner_id = "*", page = 1): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/compensations`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        partner_id: this.partner_id = partner_id,
        page: this.pagination.current_page = page,
        pagination: true,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.compensations = r.data.data;
        this.pagination = {
          current_page: r.data.current_page,
          first_page_url: r.data.first_page_url,
          from: r.data.from,
          last_page: r.data.last_page,
          last_page_url: r.data.last_page_url,
          next_page_url: r.data.next_page_url,
          path: r.data.path,
          per_page: r.data.per_page,
          prev_page_url: r.data.prev_page_url,
          to: r.data.to,
          total: r.data.total,
        };
      }
    });
    this.isLoading = false;
  }

  async getPartners(): Promise<void> {
    await getRequest({
      uri: `/common/partners`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        pagination: false,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.partners = r.data;
      }
    });
  }

  async getPaymentTypes(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/payment-types`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.paymentTypes = r.data;
      }
    });
    this.isLoading = false;
  }

  setPagination(page): void {
    this.getCompensations(this.partner_id, page);
  }

  setAppAdministratorInvoicesCompensationsListFormLookupIndexVue(compensation: AppCompensationIndex): void {
    this.compensation = compensation;
    this.$refs.AppAdministratorInvoicesCompensationsListFormLookupIndexVue.isDialogVisible = true;
  }

  setAppAdministratorInvoicesCompensationsListFormUpdateIndexVue(compensation: AppCompensationIndex): void {
    this.compensation = compensation;
    this.$refs.AppAdministratorInvoicesCompensationsListFormUpdateIndexVue.isDialogVisible = true;
  }

  setAppAdministratorInvoicesCompensationsListFormRemoveIndexVue(compensation: AppCompensationIndex): void {
    this.compensation = compensation;
    this.$refs.AppAdministratorInvoicesCompensationsListFormRemoveIndexVue.isDialogVisible = true;
  }

  async created(): Promise<void> {
    await this.getCompensations();
    await this.getPartners();
    await this.getPaymentTypes();
  }
}
