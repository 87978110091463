import {AppInvoicePaymentIndex} from "@/models/app/invoice-payment";

export interface AppCompensationIndex {
  id: string;
  organization_id: string | string[];
  invoice_payment_incoming_id: string | string[];
  invoice_payment_outgoing_id: string | string[];
  invoice_payment_incoming: AppInvoicePaymentIndex;
  invoice_payment_outgoing: AppInvoicePaymentIndex;
  ordinal_number: number | null;
  year: number | null;
  created_at: string;
  updated_at: string;
}

export class AppCompensationIndex implements AppCompensationIndex {
  id: string;
  organization_id: string | string[];
  invoice_payment_incoming_id: string | string[];
  invoice_payment_outgoing_id: string | string[];
  invoice_payment_incoming: AppInvoicePaymentIndex;
  invoice_payment_outgoing: AppInvoicePaymentIndex;
  ordinal_number: number | null;
  year: number | null;
  created_at: string;
  updated_at: string;

  constructor() {
    this.id = "";
    this.organization_id = "";
    this.invoice_payment_incoming_id = "";
    this.invoice_payment_outgoing_id = "";
    this.invoice_payment_incoming = new AppInvoicePaymentIndex;
    this.invoice_payment_outgoing = new AppInvoicePaymentIndex;
    this.ordinal_number = null;
    this.year = null;
    this.created_at = "";
    this.updated_at = "";
  }
}