
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElForm,
  ElFormItem,
  ElInput,
  ElOption,
  ElSelect,
} from "element-plus";

// Services
import {getTranslation} from "@/services/app/translation";

@Options({
  props: [
    "partners",
  ],
  components: {
    ElForm,
    ElFormItem,
    ElInput,
    ElOption,
    ElSelect,
  },
})
export default class AppAdministratorInvoicesCompensationsListFormFilterIndexVue extends Vue {
  isLoading = false;

  formData = {
    partner_id: "*",
  };

  get translation(): any {
    return getTranslation([
      "allPartners",
      "choose",
      "filter",
    ]);
  }

  $refs!: {
    formComponent: HTMLFormElement;
  };

  async submitForm(): Promise<void> {
    this.$emit("getCompensations", this.formData.partner_id);
  }
}
