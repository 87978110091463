
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store";

// Models
import {AppTransactionIndex} from "@/models/app/transaction";
import {AppInvoicePaymentCreateIncomingInvoiceCompensationPaymentIndex} from "@/models/app/invoice-payment/create-incoming-invoice-compensation-payment";
import {AppIncomingInvoiceIndex} from "@/models/app/incoming-invoice";
import {AppOutgoingInvoiceIndex} from "@/models/app/outgoing-invoice";
import {AppInvoicePaymentIndex} from "@/models/app/invoice-payment";
import {AppPaymentTypeIndex} from "@/models/app/payment-type";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElRow,
  ElCol,
  ElForm,
  ElFormItem,
  ElInput,
  ElSelect,
  ElOption,
  ElDatePicker,
  ElButton,
} from "element-plus";

import {
  Plus,
} from "@element-plus/icons-vue";

// Services
import {getRequest} from "@/services/api/request";
import {postRequest} from "@/services/api/request";
import {getCurrencyFormatHRK, getCurrencyFormatEUR, getInvoiceNumberInYear} from "@/services/app/data";
import {getToday} from "@/services/app/date";
import {getTranslation} from "@/services/app/translation";

@Options({
  props: [
    "partners",
    "paymentTypes",
  ],
  components: {
    ElDialog,
    ElRow,
    ElCol,
    ElForm,
    ElFormItem,
    ElInput,
    ElSelect,
    ElOption,
    ElDatePicker,
    ElButton,
    Plus,
  },
  watch: {
    'transaction'(): void {
      this.resetFormData();
    },
    'formData.incoming_invoice_id'(): void {
      if (this.formData.incoming_invoice_id !== "") {
        this.incomingInvoice = this.incomingInvoices.find(item => item.id === this.formData.incoming_invoice_id);
      }
    },
    'formData.outgoing_invoice_id'(): void {
      if (this.formData.outgoing_invoice_id !== "") {
        this.outgoingInvoice = this.outgoingInvoices.find(item => item.id === this.formData.outgoing_invoice_id);
      }
    },
    'formData.type'(): void {
      this.formData.partner_id = "";
      this.formData.incoming_invoice_id = "";
      this.formData.outgoing_invoice_id = "";
      this.incomingInvoice = new AppIncomingInvoiceIndex();
      this.outgoingInvoice = new AppOutgoingInvoiceIndex();

      this.outgoingInvoices = [];
      this.incomingInvoices = [];

      if (this.formData.type === 'multiple') {
        this.getIncomingInvoicesByTime();
      }
    },
    'formData.partner_id'(): void {
      if (this.formData.type === 'standard') {
        this.formData.incoming_invoice_id = "";
        this.formData.outgoing_invoice_id = "";
        this.incomingInvoice = new AppIncomingInvoiceIndex();
        this.outgoingInvoice = new AppOutgoingInvoiceIndex();

        this.getIncomingInvoicesByPartner(this.formData.partner_id);
        this.getOutgoingInvoicesByPartner(this.formData.partner_id);
      } else {
        this.formData.outgoing_invoice_id = "";
        this.outgoingInvoice = new AppOutgoingInvoiceIndex();
        this.getIncomingInvoicesByTime();
        this.getOutgoingInvoicesByPartner(this.formData.partner_id);
      }
    },
    'formData.content.amount.eur'(): void {
      this.formData.content.amount.hrk = Number((this.formData.content.amount.eur) * 7.53450).toFixed(2);
    },
  },
})
export default class AppAdministratorInvoicesCompensationsListFormCreateIncomingInvoiceCompensationPaymentIndexVue extends Vue {
  isLoading = false;
  isDialogVisible = false;

  transaction: AppTransactionIndex = new AppTransactionIndex();
  incomingInvoice: AppIncomingInvoiceIndex = new AppIncomingInvoiceIndex();
  incomingInvoices: Array<AppIncomingInvoiceIndex> = [];
  outgoingInvoice: AppOutgoingInvoiceIndex = new AppOutgoingInvoiceIndex();
  outgoingInvoices: Array<AppOutgoingInvoiceIndex> = [];
  paymentTypes: Array<AppPaymentTypeIndex> = [];

  formData: AppInvoicePaymentCreateIncomingInvoiceCompensationPaymentIndex = new AppInvoicePaymentCreateIncomingInvoiceCompensationPaymentIndex();
  formDataRules = {
    incoming_invoice_id: [
      {
        required: true,
        trigger: "blur",
      },
    ],
    outgoing_invoice_id: [
      {
        required: true,
        trigger: "blur",
      },
    ],
    content: {
      date: [
        {
          required: true,
          trigger: "blur",
        },
      ],
      amount: {
        hrk: [
          {
            required: true,
            trigger: "blur",
          },
        ],
        eur: [
          {
            required: true,
            trigger: "blur",
          },
        ],
      },
    },
    compensation_ordinal_number: [
      {
        required: true,
        trigger: "blur",
      },
    ],
    compensation_year: [
      {
        required: true,
        trigger: "blur",
      },
    ],
  };

  get translation(): any {
    return getTranslation([
      "amount",
      "choose",
      "date",
      "difference",
      "incomingCompensation",
      "incomingInvoice",
      "multiple",
      "outgoingInvoice",
      "ordinalNumber",
      "ordinalNumberPlaceholder",
      "paidAmount",
      "partners",
      "pay",
      "payAndContinue",
      "remaining",
      "spent",
      "standard",
      "titlePlaceholderAmount",
      "titlePlaceholderDate",
      "transaction",
      "type",
      "year",
      "yearPlaceholder",
    ]);
  }

  get isCompensationTypeStandard(): boolean {
    return this.formData.type === "standard";
  }

  get isIncomingInvoiceAndOutgoingInvoiceSet(): boolean {
    return this.formData.incoming_invoice_id !== "" && this.formData.outgoing_invoice_id !== "";
  }

  get isFormAmountGreaterThanAllowedIncomingInvoiceAmount(): boolean {
    return Number(this.formData.content.amount.eur) > ((Number(this.incomingInvoice.content.amount.eur) - Number(this.incomingInvoice.content.paidAmount.eur)) + 0.01);
  }

  get isFormAmountGreaterThanAllowedOutgoingInvoiceAmount(): boolean {
    return Number(this.formData.content.amount.eur) > ((Number(this.outgoingInvoice.content.amount.eur) - Number(this.outgoingInvoice.content.paidAmount.eur)) + 0.01);
  }

  get isFormAmountGreaterThanAllowedMaximumAmount(): boolean {
    return Number(this.formData.content.amount.eur) > (this.maximumAmount + 0.01);
  }

  get isPaymentDisabled(): boolean {
    if (this.isIncomingInvoiceAndOutgoingInvoiceSet) {
      return this.isFormAmountGreaterThanAllowedIncomingInvoiceAmount || this.isFormAmountGreaterThanAllowedOutgoingInvoiceAmount || this.isFormAmountGreaterThanAllowedMaximumAmount;
    }

    return true;
  }

  get isPayAndContinue(): boolean {
    return this.incomingInvoices.length > 1;
  }

  get maximumAmount(): number {
    let invoicePaymentsAmount = 0;
    let transactionAmount = parseFloat(this.transaction.content.amount.eur);

    switch (this.transaction.payment_type.content.title) {
      case "D":
        this.transaction.invoice_payments.forEach((invoicePayment: AppInvoicePaymentIndex) => {
          if (invoicePayment.incoming_invoice_id) {
            invoicePaymentsAmount = invoicePaymentsAmount + parseFloat(invoicePayment.content.amount.eur);
          }
        });
        break;
      case "P":
        this.transaction.invoice_payments.forEach((invoicePayment: AppInvoicePaymentIndex) => {
          if (invoicePayment.outgoing_invoice_id) {
            invoicePaymentsAmount = invoicePaymentsAmount + parseFloat(invoicePayment.content.amount.eur);
          }
        });
        break;
    }

    return this.getDifferenceAmount(transactionAmount, invoicePaymentsAmount);
  }

  get remainingAmount(): string {
    return this.maximumAmount.toFixed(2);
  }

  get spentAmount(): string {
    return (parseFloat(this.transaction.content.amount.eur) - this.maximumAmount).toFixed(2);
  }

  getDifferenceAmount(transactionAmount: number, invoicePaymentsAmount: number): number {
    const differenceAmount = Math.round((transactionAmount - invoicePaymentsAmount) * 100) / 100;

    if (differenceAmount.toFixed(2) === "-0.01") {
      return 0;
    }

    if (differenceAmount.toFixed(2) === "-0.00") {
      return 0;
    }

    return differenceAmount;
  }

  getCurrencyFormatHRK(data: string): string {
    return getCurrencyFormatHRK(data);
  }

  getCurrencyFormatEUR(data: string): string {
    return getCurrencyFormatEUR(data);
  }

  getInvoiceNumberInYear(number: string, year: string): string {
    return getInvoiceNumberInYear(number, year);
  }

  async getIncomingInvoicesByTime(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `common/incoming-invoices/by-time`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        searchQuery: '*',
        partner_id: '*',
        status: '0',
        pagination: 'false',
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.incomingInvoices = r.data;
      }
    });
    this.isLoading = false;
  }

  async getIncomingInvoicesByPartner(partner_id = null): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `common/incoming-invoices/by-partner`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        partner_id: partner_id ? partner_id : this.transaction.partner_id,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.incomingInvoices = r.data;
      }
    });
    this.isLoading = false;
  }

  async getOutgoingInvoicesByPartner(partner_id = null): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `common/outgoing-invoices/by-partner`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        partner_id: partner_id ? partner_id : this.transaction.partner_id,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.outgoingInvoices = r.data;
      }
    });
    this.isLoading = false;
  }

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: "/common/invoice-payment/create-incoming-invoice-compensation-payment",
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$emit('getCompensations');
        this.resetFormData();

        if (this.isPayAndContinue) {
          this.setFormData();
        } else {
          this.isDialogVisible = false;
        }
      }
    });
    this.isLoading = false;
  }

  setFormData(): void {
    let paymentType = this.paymentTypes.find((item: AppPaymentTypeIndex) => item.content.title === 'D');

    if (paymentType) {
      this.formData.payment_type_id = paymentType.id;
    }

    this.formData.transaction_id = null;
    this.formData.content.date = getToday();
    this.formData.is_compensation = true;
  }

  resetFormData(): void {
    this.formData = new AppInvoicePaymentCreateIncomingInvoiceCompensationPaymentIndex();
    this.formData.is_compensation = true;
  }

  async updated(): Promise<void> {
    this.setFormData();
  }
}
