import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-468fb0ef"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-administrator-invoices-compensations-list-form-lookup" }
const _hoisted_2 = { class: "invoice-payment" }
const _hoisted_3 = {
  key: 0,
  class: "invoice-payment__data"
}
const _hoisted_4 = { class: "invoice-payment__data-information" }
const _hoisted_5 = { class: "invoice-payment__data-information__item" }
const _hoisted_6 = { class: "invoice-payment__data-information__item-label" }
const _hoisted_7 = { class: "invoice-payment__data-information__item-value" }
const _hoisted_8 = { class: "invoice-payment__data" }
const _hoisted_9 = { class: "invoice-payment__data-information" }
const _hoisted_10 = {
  key: 0,
  class: "invoice-payment__data-information__item"
}
const _hoisted_11 = { class: "invoice-payment__data-information__item-label" }
const _hoisted_12 = { class: "invoice-payment__data-information__item-value" }
const _hoisted_13 = {
  key: 1,
  class: "invoice-payment__data-information__item"
}
const _hoisted_14 = { class: "invoice-payment__data-information__item-label" }
const _hoisted_15 = { class: "invoice-payment__data-information__item-value" }
const _hoisted_16 = { class: "invoice-payment__data-information__item" }
const _hoisted_17 = { class: "invoice-payment__data-information__item-label" }
const _hoisted_18 = { class: "invoice-payment__data-information__item-value" }
const _hoisted_19 = { class: "invoice-payment__data" }
const _hoisted_20 = { class: "invoice-payment__data-information" }
const _hoisted_21 = { class: "invoice-payment__data-information__item" }
const _hoisted_22 = { class: "invoice-payment__data-information__item-label" }
const _hoisted_23 = { class: "invoice-payment__data-information__item-value" }
const _hoisted_24 = {
  key: 0,
  class: "invoice-payment__data-information__item"
}
const _hoisted_25 = { class: "invoice-payment__data-information__item-label" }
const _hoisted_26 = { class: "invoice-payment__data-information__item-value" }
const _hoisted_27 = { class: "invoice-payment__data-information__item" }
const _hoisted_28 = { class: "invoice-payment__data-information__item-label" }
const _hoisted_29 = { class: "invoice-payment__data-information__item-value" }
const _hoisted_30 = { class: "invoice-payment__data-information__item-value__currency" }
const _hoisted_31 = { class: "invoice-payment__data-information__item-value__currency" }
const _hoisted_32 = { class: "invoice-payment__data" }
const _hoisted_33 = { class: "invoice-payment__data-information" }
const _hoisted_34 = { class: "invoice-payment__data-information__item" }
const _hoisted_35 = { class: "invoice-payment__data-information__item-label" }
const _hoisted_36 = { class: "invoice-payment__data-information__item-value" }
const _hoisted_37 = { class: "invoice-payment__data-information__item" }
const _hoisted_38 = { class: "invoice-payment__data-information__item-label" }
const _hoisted_39 = { class: "invoice-payment__data-information__item-value" }
const _hoisted_40 = { class: "invoice-payment__data-information__item" }
const _hoisted_41 = { class: "invoice-payment__data-information__item-label" }
const _hoisted_42 = { class: "incoming-invoice__data-information__item-value" }
const _hoisted_43 = { class: "invoice-payment__data" }
const _hoisted_44 = { class: "invoice-payment__data-information" }
const _hoisted_45 = { class: "invoice-payment__data-information__item" }
const _hoisted_46 = { class: "invoice-payment__data-information__item-label" }
const _hoisted_47 = { class: "incoming-invoice__data-information__item-value" }
const _hoisted_48 = { class: "invoice-payment__data-information__item" }
const _hoisted_49 = { class: "invoice-payment__data-information__item-label" }
const _hoisted_50 = { class: "incoming-invoice__data-information__item-value" }
const _hoisted_51 = {
  key: 1,
  class: "invoice-payment__action"
}
const _hoisted_52 = { class: "invoice-payment" }
const _hoisted_53 = {
  key: 0,
  class: "invoice-payment__data"
}
const _hoisted_54 = { class: "invoice-payment__data-information" }
const _hoisted_55 = { class: "invoice-payment__data-information__item" }
const _hoisted_56 = { class: "invoice-payment__data-information__item-label" }
const _hoisted_57 = { class: "invoice-payment__data-information__item-value" }
const _hoisted_58 = { class: "invoice-payment__data" }
const _hoisted_59 = { class: "invoice-payment__data-information" }
const _hoisted_60 = {
  key: 0,
  class: "invoice-payment__data-information__item"
}
const _hoisted_61 = { class: "invoice-payment__data-information__item-label" }
const _hoisted_62 = { class: "invoice-payment__data-information__item-value" }
const _hoisted_63 = {
  key: 1,
  class: "invoice-payment__data-information__item"
}
const _hoisted_64 = { class: "invoice-payment__data-information__item-label" }
const _hoisted_65 = { class: "invoice-payment__data-information__item-value" }
const _hoisted_66 = { class: "invoice-payment__data-information__item" }
const _hoisted_67 = { class: "invoice-payment__data-information__item-label" }
const _hoisted_68 = { class: "invoice-payment__data-information__item-value" }
const _hoisted_69 = { class: "invoice-payment__data" }
const _hoisted_70 = { class: "invoice-payment__data-information" }
const _hoisted_71 = { class: "invoice-payment__data-information__item" }
const _hoisted_72 = { class: "invoice-payment__data-information__item-label" }
const _hoisted_73 = { class: "invoice-payment__data-information__item-value" }
const _hoisted_74 = {
  key: 0,
  class: "invoice-payment__data-information__item"
}
const _hoisted_75 = { class: "invoice-payment__data-information__item-label" }
const _hoisted_76 = { class: "invoice-payment__data-information__item-value" }
const _hoisted_77 = { class: "invoice-payment__data-information__item" }
const _hoisted_78 = { class: "invoice-payment__data-information__item-label" }
const _hoisted_79 = { class: "invoice-payment__data-information__item-value" }
const _hoisted_80 = { class: "invoice-payment__data-information__item-value__currency" }
const _hoisted_81 = { class: "invoice-payment__data-information__item-value__currency" }
const _hoisted_82 = { class: "invoice-payment__data" }
const _hoisted_83 = { class: "invoice-payment__data-information" }
const _hoisted_84 = { class: "invoice-payment__data-information__item" }
const _hoisted_85 = { class: "invoice-payment__data-information__item-label" }
const _hoisted_86 = { class: "invoice-payment__data-information__item-value" }
const _hoisted_87 = { class: "invoice-payment__data-information__item" }
const _hoisted_88 = { class: "invoice-payment__data-information__item-label" }
const _hoisted_89 = { class: "invoice-payment__data-information__item-value" }
const _hoisted_90 = { class: "invoice-payment__data" }
const _hoisted_91 = { class: "invoice-payment__data-information" }
const _hoisted_92 = { class: "invoice-payment__data-information__item" }
const _hoisted_93 = { class: "invoice-payment__data-information__item-label" }
const _hoisted_94 = { class: "incoming-invoice__data-information__item-value" }
const _hoisted_95 = {
  key: 1,
  class: "invoice-payment__action"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_el_collapse = _resolveComponent("el-collapse")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_dialog, {
      title: _ctx.translation.review,
      class: _normalizeClass('el-dialog--invoice'),
      modelValue: _ctx.isDialogVisible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isDialogVisible) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_collapse, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_collapse_item, {
              title: `${_ctx.translation.incomingInvoice} · ${_ctx.getInvoiceNumberInYear(_ctx.invoicePaymentIncoming.incoming_invoice.content.invoiceNumberInYear, _ctx.invoicePaymentIncoming.incoming_invoice.content.year)} · ${_ctx.getInformation(_ctx.invoicePaymentIncoming.incoming_invoice.content.invoiceNumber)}`
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  (_ctx.isTransactionSet)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.translation.bank), 1),
                            _createElementVNode("span", _hoisted_7, " #" + _toDisplayString(_ctx.getInformation(_ctx.invoicePaymentIncoming.transaction.excerpt.bank.content.title)), 1)
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      (_ctx.isTransactionSet)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.translation.excerpt), 1),
                            _createElementVNode("span", _hoisted_12, " #" + _toDisplayString(_ctx.getInformation(_ctx.invoicePaymentIncoming.transaction.excerpt.content.ordinalNumber)) + "/" + _toDisplayString(_ctx.getDateYearFormat(_ctx.invoicePaymentIncoming.transaction.excerpt.content.date)), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.isTransactionSet)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.translation.transaction), 1),
                            _createElementVNode("span", _hoisted_15, " #" + _toDisplayString(_ctx.invoicePaymentIncoming.transaction.content.ordinalNumber), 1)
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.translation.date), 1),
                        _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.getDateFormat(_ctx.invoicePaymentIncoming.content.date)), 1)
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("div", _hoisted_21, [
                        _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.translation.paymentType), 1),
                        _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.invoicePaymentIncoming.payment_type.content.title), 1)
                      ]),
                      (_ctx.isTransactionSet)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                            _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.translation.paymentPurpose), 1),
                            _createElementVNode("span", _hoisted_26, " #" + _toDisplayString(_ctx.invoicePaymentIncoming.transaction.content.paymentPurpose), 1)
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_27, [
                        _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.translation.amountOfPayment), 1),
                        _createElementVNode("span", _hoisted_29, [
                          _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.getCurrencyFormatHRK(_ctx.invoicePaymentIncoming.content.amount.hrk)), 1),
                          _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.getCurrencyFormatEUR(_ctx.invoicePaymentIncoming.content.amount.eur)), 1)
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_32, [
                    _createElementVNode("div", _hoisted_33, [
                      _createElementVNode("div", _hoisted_34, [
                        _createElementVNode("span", _hoisted_35, _toDisplayString(_ctx.translation.invoiceNumberInYear), 1),
                        _createElementVNode("span", _hoisted_36, _toDisplayString(_ctx.getInvoiceNumberInYear(_ctx.invoicePaymentIncoming.incoming_invoice.content.invoiceNumberInYear, _ctx.invoicePaymentIncoming.incoming_invoice.content.year)), 1)
                      ]),
                      _createElementVNode("div", _hoisted_37, [
                        _createElementVNode("span", _hoisted_38, _toDisplayString(_ctx.translation.invoiceNumber), 1),
                        _createElementVNode("span", _hoisted_39, _toDisplayString(_ctx.getInformation(_ctx.invoicePaymentIncoming.incoming_invoice.content.invoiceNumber)), 1)
                      ]),
                      _createElementVNode("div", _hoisted_40, [
                        _createElementVNode("span", _hoisted_41, _toDisplayString(_ctx.translation.invoiceType), 1),
                        _createElementVNode("span", _hoisted_42, _toDisplayString(_ctx.getInformation(_ctx.invoicePaymentIncoming.incoming_invoice.invoice_type.content.title)), 1)
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_43, [
                    _createElementVNode("div", _hoisted_44, [
                      _createElementVNode("div", _hoisted_45, [
                        _createElementVNode("span", _hoisted_46, _toDisplayString(_ctx.translation.partner), 1),
                        _createElementVNode("span", _hoisted_47, _toDisplayString(_ctx.getInformation(_ctx.invoicePaymentIncoming.incoming_invoice.partner.content.title)), 1)
                      ]),
                      _createElementVNode("div", _hoisted_48, [
                        _createElementVNode("span", _hoisted_49, _toDisplayString(_ctx.translation.accountNumber), 1),
                        _createElementVNode("span", _hoisted_50, _toDisplayString(_ctx.getInformation(_ctx.invoicePaymentIncoming.incoming_invoice.content.accountNumber)), 1)
                      ])
                    ])
                  ]),
                  (_ctx.isTransactionSet)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
                        _createVNode(_component_router_link, {
                          class: "el-button el-button--small el-button--primary-dark",
                          to: { name: 'AppAdministratorInvoicesExcerptsDetailsIndexVue', params: { id: _ctx.invoicePaymentIncoming.transaction.excerpt.id } }
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translation.reviewExcerpt), 1)
                          ]),
                          _: 1
                        }, 8, ["to"])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }, 8, ["title"]),
            _createVNode(_component_el_collapse_item, {
              title: `${_ctx.translation.outgoingInvoice} · ${_ctx.getInvoiceNumberInYear(_ctx.invoicePaymentOutgoing.outgoing_invoice.content.invoiceNumberInYear, _ctx.invoicePaymentOutgoing.outgoing_invoice.content.year)} · ${_ctx.getInformation(_ctx.invoicePaymentOutgoing.outgoing_invoice.content.invoiceNumber)}`
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_52, [
                  (_ctx.isTransactionSet)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_53, [
                        _createElementVNode("div", _hoisted_54, [
                          _createElementVNode("div", _hoisted_55, [
                            _createElementVNode("span", _hoisted_56, _toDisplayString(_ctx.translation.bank), 1),
                            _createElementVNode("span", _hoisted_57, " #" + _toDisplayString(_ctx.getInformation(_ctx.invoicePaymentOutgoing.transaction.excerpt.bank.content.title)), 1)
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_58, [
                    _createElementVNode("div", _hoisted_59, [
                      (_ctx.isTransactionSet)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_60, [
                            _createElementVNode("span", _hoisted_61, _toDisplayString(_ctx.translation.excerpt), 1),
                            _createElementVNode("span", _hoisted_62, " #" + _toDisplayString(_ctx.getInformation(_ctx.invoicePaymentOutgoing.transaction.excerpt.content.ordinalNumber)) + "/" + _toDisplayString(_ctx.getDateYearFormat(_ctx.invoicePaymentOutgoing.transaction.excerpt.content.date)), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.isTransactionSet)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_63, [
                            _createElementVNode("span", _hoisted_64, _toDisplayString(_ctx.translation.transaction), 1),
                            _createElementVNode("span", _hoisted_65, " #" + _toDisplayString(_ctx.invoicePaymentOutgoing.transaction.content.ordinalNumber), 1)
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_66, [
                        _createElementVNode("span", _hoisted_67, _toDisplayString(_ctx.translation.date), 1),
                        _createElementVNode("span", _hoisted_68, _toDisplayString(_ctx.getDateFormat(_ctx.invoicePaymentOutgoing.content.date)), 1)
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_69, [
                    _createElementVNode("div", _hoisted_70, [
                      _createElementVNode("div", _hoisted_71, [
                        _createElementVNode("span", _hoisted_72, _toDisplayString(_ctx.translation.paymentType), 1),
                        _createElementVNode("span", _hoisted_73, _toDisplayString(_ctx.invoicePaymentOutgoing.payment_type.content.title), 1)
                      ]),
                      (_ctx.isTransactionSet)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_74, [
                            _createElementVNode("span", _hoisted_75, _toDisplayString(_ctx.translation.paymentPurpose), 1),
                            _createElementVNode("span", _hoisted_76, " #" + _toDisplayString(_ctx.invoicePaymentOutgoing.transaction.content.paymentPurpose), 1)
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_77, [
                        _createElementVNode("span", _hoisted_78, _toDisplayString(_ctx.translation.amountOfPayment), 1),
                        _createElementVNode("span", _hoisted_79, [
                          _createElementVNode("span", _hoisted_80, _toDisplayString(_ctx.getCurrencyFormatHRK(_ctx.invoicePaymentOutgoing.content.amount.hrk)), 1),
                          _createElementVNode("span", _hoisted_81, _toDisplayString(_ctx.getCurrencyFormatEUR(_ctx.invoicePaymentOutgoing.content.amount.eur)), 1)
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_82, [
                    _createElementVNode("div", _hoisted_83, [
                      _createElementVNode("div", _hoisted_84, [
                        _createElementVNode("span", _hoisted_85, _toDisplayString(_ctx.translation.invoiceNumberInYear), 1),
                        _createElementVNode("span", _hoisted_86, _toDisplayString(_ctx.getInvoiceNumberInYear(_ctx.invoicePaymentOutgoing.outgoing_invoice.content.invoiceNumberInYear, _ctx.invoicePaymentOutgoing.outgoing_invoice.content.year)), 1)
                      ]),
                      _createElementVNode("div", _hoisted_87, [
                        _createElementVNode("span", _hoisted_88, _toDisplayString(_ctx.translation.invoiceNumber), 1),
                        _createElementVNode("span", _hoisted_89, _toDisplayString(_ctx.getInformation(_ctx.invoicePaymentOutgoing.outgoing_invoice.content.invoiceNumber)), 1)
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_90, [
                    _createElementVNode("div", _hoisted_91, [
                      _createElementVNode("div", _hoisted_92, [
                        _createElementVNode("span", _hoisted_93, _toDisplayString(_ctx.translation.partner), 1),
                        _createElementVNode("span", _hoisted_94, _toDisplayString(_ctx.getInformation(_ctx.invoicePaymentOutgoing.outgoing_invoice.partner.content.title)), 1)
                      ])
                    ])
                  ]),
                  (_ctx.isTransactionSet)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_95, [
                        _createVNode(_component_router_link, {
                          class: "el-button el-button--small el-button--primary-dark",
                          to: { name: 'AppAdministratorInvoicesExcerptsDetailsIndexVue', params: { id: _ctx.invoicePaymentOutgoing.transaction.excerpt.id } }
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translation.reviewExcerpt), 1)
                          ]),
                          _: 1
                        }, 8, ["to"])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["title", "modelValue"])
  ]))
}