
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppCompensationIndex} from "@/models/app/compensation";
import {AppInvoicePaymentIndex} from "@/models/app/invoice-payment";

// Components
import {
  ElDialog,
  ElCollapse,
  ElCollapseItem,
} from "element-plus";

// Services
import {getDateFormat, getDateYearFormat} from "@/services/app/date";
import {getInformation, getCurrencyFormatHRK, getCurrencyFormatEUR, getInvoiceNumberInYear} from "@/services/app/data";
import {getTranslation} from "@/services/app/translation";

@Options({
  props: [
    "compensation",
  ],
  components: {
    ElDialog,
    ElCollapse,
    ElCollapseItem,
  },
  watch: {
    compensation() {
      this.invoicePaymentIncoming = this.compensation.invoice_payment_incoming;
      this.invoicePaymentOutgoing = this.compensation.invoice_payment_outgoing;
    }
  }
})
export default class AppAdministratorInvoicesInvoicePaymentsListFormLookupIndexVue extends Vue {
  isDialogVisible = false;

  compensation: AppCompensationIndex = new AppCompensationIndex();

  invoicePaymentIncoming: AppInvoicePaymentIndex = new AppInvoicePaymentIndex();
  invoicePaymentOutgoing: AppInvoicePaymentIndex = new AppInvoicePaymentIndex();

  get translation(): any {
    return getTranslation([
      "accountNumber",
      "amountOfPayment",
      "bank",
      "date",
      "excerpt",
      "invoiceNumber",
      "invoiceNumberInYear",
      "invoicePayment",
      "invoiceType",
      "incomingInvoice",
      "outgoingInvoice",
      "paymentType",
      "partner",
      "paymentPurpose",
      "review",
      "reviewExcerpt",
      "transaction",
    ]);
  }

  get isTransactionSet(): boolean {
    return this.invoicePaymentIncoming.transaction_id !== null || this.invoicePaymentOutgoing.transaction_id !== null;
  }

  getDateFormat(data: string): string {
    return getDateFormat(data);
  }

  getDateYearFormat(data: string): string {
    return getDateYearFormat(data);
  }

  getInformation(data: string): string {
    return getInformation(data);
  }

  getCurrencyFormatHRK(data: string): string {
    return getCurrencyFormatHRK(data);
  }

  getCurrencyFormatEUR(data: string): string {
    return getCurrencyFormatEUR(data);
  }

  getInvoiceNumberInYear(number: string, year: string): string {
    return getInvoiceNumberInYear(number, year);
  }
}
