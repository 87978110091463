import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1de9ec10"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-administrator-invoices-compensations-list" }
const _hoisted_2 = { class: "box" }
const _hoisted_3 = { class: "box__headline" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "action" }
const _hoisted_6 = { class: "box__filter" }
const _hoisted_7 = {
  key: 0,
  class: "box__list"
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { class: "el-table-column__action" }
const _hoisted_17 = { class: "el-table-column__action" }
const _hoisted_18 = { class: "el-table-column__action" }
const _hoisted_19 = {
  key: 0,
  class: "pagination"
}
const _hoisted_20 = {
  key: 1,
  class: "box__message"
}
const _hoisted_21 = {
  key: 2,
  class: "box__loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppAdministratorInvoicesCompensationsListFormCreateIncomingInvoiceCompensationPaymentIndexVue = _resolveComponent("AppAdministratorInvoicesCompensationsListFormCreateIncomingInvoiceCompensationPaymentIndexVue")!
  const _component_AppAdministratorInvoicesCompensationsListFormFilterIndexVue = _resolveComponent("AppAdministratorInvoicesCompensationsListFormFilterIndexVue")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_LoadingIndexVue = _resolveComponent("LoadingIndexVue")!
  const _component_AppAdministratorInvoicesCompensationsListFormLookupIndexVue = _resolveComponent("AppAdministratorInvoicesCompensationsListFormLookupIndexVue")!
  const _component_AppAdministratorInvoicesCompensationsListFormUpdateIndexVue = _resolveComponent("AppAdministratorInvoicesCompensationsListFormUpdateIndexVue")!
  const _component_AppAdministratorInvoicesCompensationsListFormRemoveIndexVue = _resolveComponent("AppAdministratorInvoicesCompensationsListFormRemoveIndexVue")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.translation.compensations), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_AppAdministratorInvoicesCompensationsListFormCreateIncomingInvoiceCompensationPaymentIndexVue, {
            partners: _ctx.partners,
            "payment-types": _ctx.paymentTypes,
            onGetCompensations: _ctx.getCompensations
          }, null, 8, ["partners", "payment-types", "onGetCompensations"])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_AppAdministratorInvoicesCompensationsListFormFilterIndexVue, {
          partners: _ctx.partners,
          onGetCompensations: _ctx.getCompensations
        }, null, 8, ["partners", "onGetCompensations"])
      ]),
      (_ctx.isDataLoaded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_el_table, {
              data: _ctx.compensations,
              style: {"width":"100%"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.ordinalNumber}`,
                  width: "70"
                }, {
                  default: _withCtx((scope) => [
                    (_ctx.getIsOrdinalNumberSet(scope.row))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_8, " #" + _toDisplayString(scope.row.ordinal_number) + "/" + _toDisplayString(scope.row.year), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_9, "N/A"))
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.excerpt.substring(0,3)}.`,
                  width: "40"
                }, {
                  default: _withCtx((scope) => [
                    (_ctx.getIsTransactionSet(scope.row))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_10, " #" + _toDisplayString(scope.row.invoice_payment_incoming.transaction.excerpt.content.ordinalNumber), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_11, "N/A"))
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.transaction.substring(0,3)}.`,
                  width: "40"
                }, {
                  default: _withCtx((scope) => [
                    (_ctx.getIsTransactionSet(scope.row))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, " #" + _toDisplayString(scope.row.invoice_payment_incoming.transaction.content.ordinalNumber), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_13, "N/A"))
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.date}`,
                  width: "80"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(_ctx.getDateFormat(scope.row.created_at)), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.paymentType.substring(0,3)}.`,
                  width: "40"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(scope.row.invoice_payment_incoming.payment_type.content.title), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.partner}`,
                  "min-width": "200"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(scope.row.invoice_payment_incoming.incoming_invoice.partner.content.title.substring(0,24)), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.paymentPurpose}`,
                  "min-width": "360"
                }, {
                  default: _withCtx((scope) => [
                    (_ctx.getIsTransactionSet(scope.row))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(scope.row.invoice_payment_incoming.transaction.content.paymentPurpose), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_15, "N/A"))
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.invoiceNumberInYearIRA}`,
                  width: "120"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(_ctx.getInvoiceNumberInYear(scope.row.invoice_payment_incoming.incoming_invoice.content.invoiceNumberInYear, scope.row.invoice_payment_incoming.incoming_invoice.content.year)), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.invoiceNumber}`,
                  width: "120"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(_ctx.getInformation(scope.row.invoice_payment_incoming.incoming_invoice.content.invoiceNumber)), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.invoiceNumberInYearURA}`,
                  width: "120"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(_ctx.getInvoiceNumberInYear(scope.row.invoice_payment_outgoing.outgoing_invoice.content.invoiceNumberInYear, scope.row.invoice_payment_outgoing.outgoing_invoice.content.year)), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.invoiceNumber}`,
                  width: "120"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(_ctx.getInformation(scope.row.invoice_payment_outgoing.outgoing_invoice.content.invoiceNumber)), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.amount}`,
                  width: "100"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(_ctx.getCurrencyFormatEUR(scope.row.invoice_payment_incoming.content.amount.eur)), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.review}`,
                  width: "132",
                  fixed: "right",
                  align: "center"
                }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("div", _hoisted_16, [
                      _createVNode(_component_el_button, {
                        class: "el-button--primary el-button--extra-small el-button--block",
                        onClick: ($event: any) => (_ctx.setAppAdministratorInvoicesCompensationsListFormLookupIndexVue(scope.row))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translation.review), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.edit}`,
                  width: "132",
                  fixed: "right",
                  align: "center"
                }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("div", _hoisted_17, [
                      _createVNode(_component_el_button, {
                        class: "el-button--primary el-button--extra-small el-button--block",
                        onClick: ($event: any) => (_ctx.setAppAdministratorInvoicesCompensationsListFormUpdateIndexVue(scope.row))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translation.edit), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.remove}`,
                  width: "132",
                  fixed: "right",
                  align: "center"
                }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("div", _hoisted_18, [
                      _createVNode(_component_el_button, {
                        class: "el-button--primary-dark el-button--extra-small el-button--block",
                        onClick: ($event: any) => (_ctx.setAppAdministratorInvoicesCompensationsListFormRemoveIndexVue(scope.row))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translation.remove), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }, 8, ["data"]),
            (_ctx.isPaginationVisible)
              ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                  _createVNode(_component_el_pagination, {
                    background: "",
                    layout: "prev, pager, next",
                    "page-size": 24,
                    total: _ctx.pagination.total,
                    "current-page": _ctx.pagination.current_page,
                    onCurrentChange: _ctx.setPagination
                  }, null, 8, ["total", "current-page", "onCurrentChange"])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.isDataLoadedAndEmpty)
        ? (_openBlock(), _createElementBlock("div", _hoisted_20, _toDisplayString(_ctx.translation.noData), 1))
        : _createCommentVNode("", true),
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
            _createVNode(_component_LoadingIndexVue)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_AppAdministratorInvoicesCompensationsListFormLookupIndexVue, {
      ref: "AppAdministratorInvoicesCompensationsListFormLookupIndexVue",
      compensation: _ctx.compensation
    }, null, 8, ["compensation"]),
    _createVNode(_component_AppAdministratorInvoicesCompensationsListFormUpdateIndexVue, {
      ref: "AppAdministratorInvoicesCompensationsListFormUpdateIndexVue",
      compensation: _ctx.compensation,
      "partner-id": _ctx.partner_id,
      pagination: _ctx.pagination,
      onGetCompensations: _ctx.getCompensations
    }, null, 8, ["compensation", "partner-id", "pagination", "onGetCompensations"]),
    _createVNode(_component_AppAdministratorInvoicesCompensationsListFormRemoveIndexVue, {
      ref: "AppAdministratorInvoicesCompensationsListFormRemoveIndexVue",
      compensation: _ctx.compensation,
      "partner-id": _ctx.partner_id,
      pagination: _ctx.pagination,
      onGetCompensations: _ctx.getCompensations
    }, null, 8, ["compensation", "partner-id", "pagination", "onGetCompensations"])
  ]))
}